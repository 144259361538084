<template>
  <div class="myOperation">
    <div v-for="(item, index) in studentWorkList" :key="index" class="myOperation_item">
      <div class="myOperation_item_left">
        <div class="myOperation_item_left_img">
          <el-image :src="require('@/assets/img/homeSeventh/zuoye01.png')" />
        </div>
        <div class="myOperation_item_left_title">
          <div class="myOperation_item_left_title_name">
            <div class="myOperation_item_left_title_name_left ellipsis">
              {{ item.workName }}
            </div>
            <span class="myOperation_item_left_title_state" :class="item.commitStatus ? 'submitTrue' : ''">
              {{ item.commitStatus == 1 ? "已提交" : "未提交" }}
            </span>
          </div>
          <p v-if="item.commitTime" class="myOperation_item_left_title_state_submitTime">
            提交时间:{{ item.commitTime }}
          </p>
        </div>
      </div>
      <div class="myOperation_item_right">
        <div v-if="item.checkStatus && item.grade == 3" class="myOperation_item_right_img">
          <el-image :src="require('@/assets/img/homeSeventh/good.png')" />
        </div>
        <div v-else-if="item.checkStatus && item.grade == 2" class="myOperation_item_right_img">
          <el-image :src="require('@/assets/img/homeSeventh/grade2.png')" />
        </div>
        <div v-else-if="item.checkStatus && item.grade == 1" class="myOperation_item_right_img">
          <el-image :src="require('@/assets/img/homeSeventh/grade1.png')" />
        </div>
        <div v-else-if="item.checkStatus && item.grade == 0" class="myOperation_item_right_img">
          <el-image :src="require('@/assets/img/homeSeventh/grade0.png')" />
        </div>
        <span v-if="item.checkStatus == 1" class="myOperation_item_right_state"><i class="iconfont el-icon-check" />
          已批改</span>
        <div v-if="item.commitStatus == 1 && item.checkStatus != 1" class="myOperation_item_right_state">
          <img src="@/assets/img/homeSeventh/pigaizhong.png" alt="" />
          <span>批改中</span>
        </div>
        <el-button v-if="item.commitStatus == 0" class="myOperation_item_right_btn"
          @click="goDetails(item)">完成作业</el-button>
        <el-button v-else :class="item.commitStatus == 1 ? 'submitTrue' : ''" class="myOperation_item_right_btn"
          @click="goDetails(item)">查看详情</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getStudentWorkList, submitWork } from "@/api/myOp";
export default {
  components: {},
  props: {},
  data() {
    return {
      studentWorkList: [],
      commitStatus: 0,
    };
  },
  computed: {},
  watch: {},
  async created() {
    await this.getStudentWorkList();
  },
  mounted() { },
  methods: {
    async getStudentWorkList() {
      try {
        const { rows } = await getStudentWorkList();
        if (rows) {
          this.studentWorkList = rows;
        }
      } catch (error) {
        ;
      }
    },
    goDetails(item) {

      this.$router.push(
        `/seventhPage/myOperationDetails?workId=${item.workId}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.myOperation {
  width: 880px;
  // height: 480px;
  // background-color: #333;

  .line {
    width: 816px;
    height: 1px;
    background: #eeeeee;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .myOperation_item {
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
    width: 880px;
    height: 110px;
    border-bottom: 1px solid #eeeeee;
    background-color: #fff;

    .myOperation_item_left {
      display: flex;
      width: 600px;

      .myOperation_item_left_img {
        margin-top: 24px;
        width: 70px;
        height: 70px;
        padding: 15px;
        background: #f5f5f5;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
      }

      .myOperation_item_left_title {
        width: 400px;
        margin: 28px 0 9px 12px;

        .myOperation_item_left_title_name {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .myOperation_item_left_title_name_left {
            overflow: hidden;
            max-width: 335px;
            // height: 25px;
            margin-right: 10px;
            font-size: 18px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }

          .myOperation_item_left_title_state {
            display: inline-block;
            // width: 45px;
            height: 21px;
            line-height: 21px;
            padding: 0 4px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            background: #ffebf1;
            font-weight: 400;
            color: #fe4473;
          }

          .submitTrue {
            color: #2eb988;
            background: #eaf9f2;
          }
        }

        .myOperation_item_left_title_state_submitTime {
          width: 217px;
          // height: 20px;
          margin-left: 2px;
          margin-top: 9px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }

    .myOperation_item_right {
      width: 300px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      // margin: auto;
      .myOperation_item_right_img {
        width: 53px;
        height: 53px;
        margin-right: 40px;
      }

      .myOperation_item_right_state {
        display: flex;
        align-items: center;
        // width: 100px;
        height: 20px;
        justify-content: start;
        margin-right: 21px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;

        img {
          width: 11px;
          height: 11px;
        }
      }

      /deep/ .myOperation_item_right_btn {
        width: 87px;
        height: 29px;
        line-height: 0;
        padding-left: 16px;
        color: #ff5e51;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #ff5e51;
      }

      /deep/ .submitTrue {
        color: #999999 !important;
        border: 1px solid #999999 !important;
      }
    }
  }
}
</style>
